import axios, { AxiosResponse } from "axios";
import { Response } from "./GeneralResponse";

// 创建axios实例
const instance = axios.create({
  baseURL: "https://api.viniton-group.com",
  withCredentials: true,

  // baseURL: "/api",
  // 可以在这里设置一些默认配置，比如baseURL
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在这里可以做一些请求前的操作，比如设置token
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  },
);

// 响应拦截器
instance.interceptors.response.use(
  (response: AxiosResponse<Response>) => {
    // 在这里可以做一些响应后的操作
    const responseData: Response = response.data;
    if (responseData.code.toString().startsWith("2")) {
      return responseData.data;
    } else {
      return Promise.reject(responseData.message);
    }
  },
  (error) => {
    // 响应错误处理
    if (error.response) {
      // 服务器返回了错误的状态码
      const responseData: Response = error.response.data;
      return Promise.reject(responseData.message);
    } else if (error.request) {
      // 请求已发出，但没有收到响应
      return Promise.reject("Network error");
    } else {
      // 发送请求时出了点问题
      return Promise.reject(error.message);
    }
  },
);

// 封装的函数
const get = async (url: string, params: any): Promise<any> => {
  return instance.get(url, { params });
};

const post = async (url: string, data: any): Promise<any> => {
  return instance.post(url, data);
};

export { get, post };
