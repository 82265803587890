import {RouterProvider} from "react-router-dom";
import router from "../../utils/router/RouterSettings";


const ContentImpl = () => {
    return (
            <RouterProvider router={router} />
    );
};

export default ContentImpl
