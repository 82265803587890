import React, { useEffect, useState } from "react";
import {
  getProductList,
  ProductInterface,
  searchProduct,
} from "../../utils/axios/api/ProductInterface";
import { InfiniteScroll, List, NavBar, SearchBar } from "antd-mobile";
import ProductPanel from "./ProductPanel";
import router from "../../utils/router/RouterSettings";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

type TranslationType = {
  [key: string]: {
    translation: {
      [key: string]: string;
    };
  };
};

const addProductTitleTranslation = (product: ProductInterface) => {
  const translation: TranslationType = {
    zh: {
      translation: {},
    },
    en: {
      translation: {},
    },
    km: {
      translation: {},
    },
  };
  translation.zh.translation[product.TitleEn + "title" + product.ID] =
    product.TitleZh;
  translation.en.translation[product.TitleEn + "title" + product.ID] =
    product.TitleEn;
  translation.km.translation[product.TitleEn + "title" + product.ID] =
    product.TitleKm;
  i18n.addResourceBundle(
    "en",
    "translation",
    translation.en.translation,
    true,
    true,
  );
  i18n.addResourceBundle(
    "zh",
    "translation",
    translation.zh.translation,
    true,
    true,
  );
  i18n.addResourceBundle(
    "km",
    "translation",
    translation.km.translation,
    true,
    true,
  );
};

const ProductList = () => {
  const [products, setProducts] = useState<ProductInterface[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchWord, setSearchWord] = useState<string>("");

  const { t } = useTranslation();

  const onSearch = async () => {
    setProducts([]);
    setCurrentPage(0);
    setHasMore(true);
  };

  const onClear = async () => {
    setProducts([]);
    setSearchWord("");
    setCurrentPage(0);
    setHasMore(true);
  };

  const onCancel = async () => {
    setProducts([]);
    setSearchWord("");
    setCurrentPage(0);
    setHasMore(true);
  };

  const onChange = async (value: string) => {
    setSearchWord(value);
  };

  const loadList = async (isRetry: boolean) => {
    getProductList(currentPage + 1)
      .then((res) => {
        setProducts([...products, ...res]);
        setHasMore(res.length > 0);
        if (!isRetry) setCurrentPage(currentPage + 1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchList = async (isRetry: boolean) => {
    searchProduct(searchWord, currentPage + 1)
      .then((res) => {
        setProducts([...products, ...res]);
        setHasMore(res.length > 0);
        if (!isRetry) setCurrentPage(currentPage + 1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const goBackToIndex = async () => {
    await router.navigate("/");
  };

  const loadMore = async (isRetry: boolean) => {
    if (loading) return;
    setLoading(true);
    if (searchWord === "") {
      await loadList(isRetry);
    } else {
      await searchList(isRetry);
    }
  };
  return (
    <>
      <List className={"list-style"}>
        <NavBar onBack={goBackToIndex} className={"nav-bar"}>
          <SearchBar
            placeholder={t("Search products")}
            className={"search-bar"}
            showCancelButton
            onSearch={onSearch}
            onCancel={onCancel}
            onChange={onChange}
            onClear={onClear}
          />
        </NavBar>

        {products.map((product: ProductInterface) => {
          addProductTitleTranslation(product);
          return <ProductPanel product={product} />;
        })}
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
      </List>
    </>
  );
};

export default ProductList;
