import { TabBar } from "antd-mobile";
import { useState } from "react";
import { AppOutline, AppstoreOutline } from "antd-mobile-icons";
import router from "../../utils/router/RouterSettings";
import { useTranslation } from "react-i18next";

const FooterNavbar = () => {
  const tabs = [
    {
      key: "/index",
      title: "Index",
      icon: <AppOutline />,
    },
    {
      key: "/product",
      title: "Products",
      icon: <AppstoreOutline />,
    },
  ];
  const { t } = useTranslation();

  const onChange = (key) => {
    setActiveKey(key);
    router.navigate(key).then((res) => {});
  };

  const [activeKey, setActiveKey] = useState("");
  return (
    <TabBar activeKey={activeKey} onChange={onChange}>
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={t(item.title)} />
      ))}
    </TabBar>
  );
};

export default FooterNavbar;
