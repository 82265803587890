import { createBrowserRouter } from "react-router-dom";
import ProductList from "../../pages/ProductList";
import Index from "../../pages/Index";

const router = createBrowserRouter([
  {
    path: "/product",
    element: <ProductList />,
  },
  {
    path: "/index",
    element: <Index />,
  },
  {
    path: "/",
    element: <Index />,
  },
]);

export default router;
