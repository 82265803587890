import React, { FC } from "react";

import "./product_list.css";
import { Col, Row } from "antd";

export declare type ProductPriceProps = {
  price: number;
};

const ProductPrice: FC<ProductPriceProps> = ({ price }) => {
  return (
    <>
      <Row>
        <Col className={"product-price-prefix"}>{"$"}</Col>
        <Col className={"product-price"}>{price}</Col>
      </Row>
      {/*<div className={"product-price"}>{" " + }</div>*/}
    </>
  );
};
export default ProductPrice;
