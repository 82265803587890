import React, { FC, useEffect } from "react";
import { ProductInterface } from "../../utils/axios/api/ProductInterface";
import { Divider, List } from "antd-mobile";
import "./product_list.css";
import ProductDescription from "./ProductDescription";
import ProductPrice from "./ProductPrice";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

export declare type ProductPanelProps = {
  product: ProductInterface;
};
const ProductPanel: FC<ProductPanelProps> = ({ product }) => {
  const { t } = useTranslation();
  return (
    <>
      <List.Item
        className={"list-item"}
        key={product.ID}
        prefix={
          <img alt={"img"} src={product.Images?.[0]} className={"list-img"} />
        }
        title={
          <div className={"panel-title"}>
            {t(product.TitleEn + "title" + product.ID)}
          </div>
        }
        clickable
      >
        <div className={"list-item-content"}>
          <Row>
            <Col>
              <ProductDescription
                title={t("Nicotine")}
                value={product.Nicotine + " " + t("mg/tube")}
              />
            </Col>
            <Divider
              direction="vertical"
              className={"description-divider"}
            ></Divider>
            <Col>
              <ProductDescription
                title={t("Net Content")}
                value={product.NetContent + " " + t("PCS")}
              />
            </Col>
          </Row>
          <ProductPrice price={product.PriceUSD} />
        </div>
      </List.Item>
    </>
  );
};

export default ProductPanel;
