
import FooterNavbar from "./components/FooterNavbar"
import FloatButton from "./components/FloatButton";
import ContentImpl from "./components/ContentImpl";
import "./App.css"

function App() {

  return (
      <div className="app">
          <div className={"body"}>
              <ContentImpl/>
          </div>
          <div className={"bottom"}></div>
          <FloatButton/>
          <FooterNavbar/>
      </div>
  );
}

export default App;
