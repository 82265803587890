import React, { FC } from "react";
import "./product_list.css";

export declare type ProductDescriptionProps = {
  title: string;
  value: string | number;
};
const ProductDescription: FC<ProductDescriptionProps> = ({ title, value }) => {
  return (
    <>
      <div className={"description-value"}>{value}</div>
      <div className={"description-title"}>{title}</div>
    </>
  );
};

export default ProductDescription;
