import { get } from "../Request";
export interface ProductInterface {
  DescriptionEn: string;
  DescriptionKm: string;
  DescriptionZh: string;
  ID: number;
  Images: string[] | null;
  PriceKHR: number;
  PriceUSD: number;
  TitleEn: string;
  TitleKm: string;
  TitleZh: string;
  Origin: string;
  NetContent: number;
  Nicotine: number;
  Smoke: number;
  [property: string]: any;
}

// export async function editProduct(product: ProductInterface) {
//   try {
//     const response: ProductInterface = await post(
//       "/admin/editProduct",
//       product,
//     );
//     if (response) {
//       return response;
//     } else {
//       console.error("No data returned from editProduct.");
//       return null;
//     }
//   } catch (error) {
//     console.error("Error updating the product data:", error);
//     return null;
//   }
// }

export async function getProductById(id: number) {
  try {
    const response: ProductInterface = await get("/product/get", {
      id: id,
    });
    if (response) {
      return response;
    } else {
      console.error("No data returned from getProductList.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching product list:", error);
    return null;
  }
}

export async function getProductList(page: Number) {
  try {
    const response: ProductInterface[] = await get("/product/get_list", {
      page,
    });
    if (response) {
      return response;
    } else {
      console.error("No data returned from getProductList.");
      return [];
    }
  } catch (error) {
    console.error("Error fetching product list:", error);
    return [];
  }
}

export async function searchProduct(re: string, page: number) {
  try {
    const response: ProductInterface[] = await get("/product/search", {
      re,
      page,
    });
    if (response) {
      return response;
    } else {
      console.error("No data returned from getProductList.");
      return [];
    }
  } catch (error) {
    console.error("Error fetching product list:", error);
    return [];
  }
}
//
// export async function getUploadProductImageUrl(filename: string) {
//   const response: UploadProductImageUrlResponseInterface = await get(
//     "/admin/getUploadProductImageUrl",
//     {
//       filename,
//     },
//   );
//   if (response) {
//     return response;
//   } else {
//     throw new Error("No data returned from getUploadProductImageUrl.");
//   }
// }
//
// export async function addProductImageInfo(keyName: string) {
//   try {
//     await post("/admin/addProductImageInfo", {
//       keyName,
//     });
//   } catch (error) {
//     console.error("Error adding product image info:", error);
//     return false;
//   }
// }
